<template>
  <div
    class="w-100 col-lg-12 col-md-12 col-sm-12 m-0 p-0"
  >
    <video
      id="video-stream"
      class="fixWidth"
      autoplay="autoplay"
      playsinline
      muted
    />
  </div></template>

<script>
import simmiIOT from '@/auth/simmiIOT'

let localPeerConnection
let timestampStartLocalStream
let localSendChannel
let remoteStream
let offerSDP = ''
let timestampCreateSdpOffer
let timestampCreateSdpOfferSuccess
let timestampSetLocalDescription
let timestampSetLocalDescriptionSuccess
let timestampSetRemoteDescription
let timestampSetRemoteDescriptionSuccess
let timestampConnected
let localStream
let initialized = false
let videoElement
let timestampInitializeWebRTC

// Camera Stream button pressed
let timestampPlaybackStarted
// WebRTC Configurations:

const localOfferOptions = {
  offerToReceiveVideo: 1,
  offerToReceiveAudio: 1,
}
const handleReceiveMessage = event => {
  console.log('Incoming DataChannel push', event)
  document.getElementById('video-stream').srcObject = remoteStream
}
const receiveChannelCallback = event => {
  console.log('receiveChannelCallback')
  const receiveChannel = event.channel
  receiveChannel.onmessage = handleReceiveMessage
}
export default {
  data() {
    return {
      loadStream: false,
      streamId: null,
      stream: {},
      nestProjectId: process.env.NEST_PROJECT_ID,
      nestDeviceId: null,
      nestAUthToken: null,
      logoutLoading: false,
      loginLoading: false,
    }
  },
  computed: {},
  async mounted() {
    this.streamId = Number(this.$route.query.streamId)
    this.loadStream = true
    await this.getStreamData()
  },
  methods: {
    async getStreamData() {
      await simmiIOT.get(`/nest/token/nest-stream-data?streamId=${this.streamId}`).then(async resp => {
        if (resp.data.success) {
          this.nestDeviceId = resp.data.response.nest_device_id
          this.nestAUthToken = resp.data.response.auth_token
          await this.initializeWebRTC()
        } else {
          this.$swal(resp.data.message)
        }
      }).catch(error => {
        console.log('error', error.response)
        this.$swal(error.message)
      })
    },
    handleConnectionChange(event) {
      console.log('ICE state change event: ')
      if (event != null && event.currentTarget != null && event.target.iceConnectionState === 'connected') {
        if (timestampConnected === undefined) {
          timestampConnected = new Date()
          console.log('connected - ', timestampConnected)
        }
      }
    },
    gotRemoteMediaTrack(event) {
      console.log('gotRemoteMediaTrack()')
      remoteStream.addTrack(event.track)
      // document.getElementById('video-stream').srcObject = remoteStream
      this.stream = remoteStream
      console.log('This is Stream')
      console.log('Received remote track.')
    },
    async initializeWebRTC() {
      if (initialized === true) return
      timestampInitializeWebRTC = new Date()
      // updateAnalytics()
      console.log('initializeWebRTC() - ', timestampInitializeWebRTC)

      videoElement = document.getElementById('video-stream')
      console.log('videoElement', videoElement)
      videoElement.addEventListener('play', event => {
        timestampPlaybackStarted = new Date()
        console.log(event)
        // updateAnalytics()
        console.log('playback started - ', timestampPlaybackStarted)
      })

      initialized = true
      console.log(initialized)
      this.createOffer()
    },
    createOffer(mediaStream) {
      console.log('Create Offer SDP')
      timestampStartLocalStream = new Date()
      // updateAnalytics()
      console.log('startLocalStream() - ', timestampStartLocalStream)
      localPeerConnection = null
      localSendChannel = null
      localStream = null
      offerSDP = ''

      remoteStream = new MediaStream()

      const servers = { sdpSemantics: 'unified-plan', iceServers: [] }
      localPeerConnection = new RTCPeerConnection(servers)
      localPeerConnection.ondatachannel = receiveChannelCallback

      localSendChannel = localPeerConnection.createDataChannel('dataSendChannel', null)
      console.log('This is an local channel', localSendChannel)
      localPeerConnection.addEventListener('iceconnectionstatechange', this.handleConnectionChange)

      if (mediaStream) {
        mediaStream.getTracks().forEach(track => {
          localPeerConnection.addTrack(track, mediaStream)
          console.log('track added!')
        })
        localStream = mediaStream
        console.log('local stream', localStream)
      }

      localPeerConnection.addEventListener('track', this.gotRemoteMediaTrack)

      timestampCreateSdpOffer = new Date()
      console.log('localPeerConnection createOffer start - ', timestampCreateSdpOffer)
      localPeerConnection.createOffer(localOfferOptions)
        .then(this.createdOffer).catch(e => {
          console.log('session Error 1', e)
        })
    },
    setLocalDescriptionSuccess(peerConnection) {
      timestampSetLocalDescriptionSuccess = new Date()
      // updateAnalytics()
      console.log('setLocalDescriptionSuccess() - ', timestampSetLocalDescriptionSuccess)
      this.setDescriptionSuccess(peerConnection, 'setLocalDescription')
    },
    getPeerName(peerConnection) {
      console.log('getPeerName()')
      return (peerConnection === localPeerConnection)
        ? 'localPeerConnection' : 'remotePeerConnection'
    },
    setDescriptionSuccess(peerConnection, functionName) {
      console.log('setDescriptionSuccess()')
      const peerName = this.getPeerName(peerConnection)
      console.log(`${peerName} ${functionName} complete`)
    },
    updateOfferSDP(value) {
      offerSDP = value
      console.log('This is an SDP')
      this.getStreamFromAPI(offerSDP)
    },
    createdOffer(description) {
      timestampCreateSdpOfferSuccess = new Date()
      // updateAnalytics()
      console.log('createdOffer() - ', timestampCreateSdpOfferSuccess)
      console.log('This is an SDP description')
      this.updateOfferSDP(description.sdp)
      timestampSetLocalDescription = new Date()
      // updateAnalytics()
      console.log('setLocalDescription() - ', timestampSetLocalDescription)
      localPeerConnection.setLocalDescription(description)
        .then(() => {
          this.setLocalDescriptionSuccess(localPeerConnection)
        }).catch(e => {
          console.log('session Error', e)
        })
    },
    setRemoteDescriptionSuccess(peerConnection) {
      timestampSetRemoteDescriptionSuccess = new Date()
      // updateAnalytics()
      console.log('setRemoteDescriptionSuccess() - ', timestampSetRemoteDescriptionSuccess)
      this.setDescriptionSuccess(peerConnection, 'setRemoteDescription')
    },
    updateWebRTC(answerSDP) {
      console.log('Answer from remotePeerConnection ')
      if (answerSDP[answerSDP.length - 1] !== '\n') {
        // eslint-disable-next-line no-param-reassign
        answerSDP += '\n'
      }

      timestampSetRemoteDescription = new Date()
      // updateAnalytics()
      console.log('setRemoteDescription() - ', timestampSetRemoteDescription)
      localPeerConnection.setRemoteDescription({ type: 'answer', sdp: answerSDP })
        .then(() => {
          this.setRemoteDescriptionSuccess(localPeerConnection)
        }).catch(e => {
          console.log('session Error', e)
        })
    },
    async getStreamFromAPI(offerSdp) {
      await simmiIOT.post(`https://smartdevicemanagement.googleapis.com/v1/enterprises/${process.env.VUE_APP_NEST_PROJECT_ID}/devices/${this.nestDeviceId}:executeCommand`,
        {
          command: 'sdm.devices.commands.CameraLiveStream.GenerateWebRtcStream',
          params: { offerSdp },
        }, {
          headers: {
            Authorization: this.nestAUthToken,
          },
        }).then(resp => {
        console.log('Answer SDP')
        this.updateWebRTC(resp.data.results.answerSdp)
      }).catch(error => {
        console.log('API Error', error)
        this.$swal('Unauthenticated')
      })
    },
  },
}
</script>
<style lang="scss">
.fixWidth{
  width: 100% !important;
  height: 100% !important;
}
</style>
